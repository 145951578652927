.App {
  text-align: center;
}

body {
  font-family: "Titillium Web";
  font-size: 16px;
}

.logo-section strong {
    display: inline;
}

.logo-section p {
  color: white;
  /* font-family: "Titillium Web";
  font-size: 16px;
  margin-left: 1rem;*/
  padding-top: 8px;
}

.logo-section {
  font-size: 1rem;
  font-family: "Titillium Web";
}

.logo-section a {
  display: inline-block;
  color: white;
}

.logo-section a:hover {
  color: white;
}

.main-logo {
  height: 150px;
  float: left;
  pointer-events: none;
  position: relative;
  top: 40px;
}

.logo {
  height: 40px;
  float: left;
  pointer-events: none;
}

.mainBanner {
  min-height: 160px;
  background-color: #46b8da;
  color: white;
  font-size: 2.7rem;
}
.table thead {
  border-bottom: black 1px solid;
}

.table td:first-child,
.table th:first-child {
  border-right: black 1px solid;
}

.table th {
  padding: 5px !important;
  font-size: 18px;
  border-bottom: 1px solid #0f0f0f !important;
}

.table td {
  padding: 5px !important;
  font-size: 18px;
  border-bottom: 0px solid transparent !important;
}


.table th:nth-child(n+2),
.table td:nth-child(n+2) {
  text-align: right !important;
}

.bg-bar{
  fill: rgb(23, 50, 77);
  stroke: rgb(23, 50, 77);
}

.it-header-slim-wrapper {
  height: 55px !important;
  padding: 0;
}

.bg-title-plot {
  background-color: #F4F9FD;
  height: 200px;
}


/*
 * Footer
 *
 */

.footer-wrapper strong {
    font-weight: bold;
}

.footer-wrapper {
  background-color: #46b8da;
  color: white;
}

.logo_text h1, .logo_text .h1, .logo_text h2, .logo_text .h2 {
    font-size: 1.778em;
    font-weight: 700;
    line-height: 1.2em;
    color: #ffffff;
    display: block;
    margin: 8px 0 0 0;
    letter-spacing: -0.5pt;
}

strong {
    display: block;
}

.logo_wrapper {
    width: 74px;
    height: auto;
    display: block;
    position: absolute;
    z-index: 100;
    top: 5px;
    left: 0;
    transition: all 0.3s ease;
}

.footer_container .logo_container {
    margin-top: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
}

.logo_container {
    margin: 0;
    margin: 48px 0 0 120px;
    margin-top: 48px;
    margin-bottom: 0px;
    margin-left: 120px;
    position: relative;
    z-index: 0;
}

.logo_text {
    padding: 0 0 0 100px;
}

.logo_wrapper a {
    display: block;
}

.h3_footer {
    font-size: 24px;
}

.footer-wrapper h4 {
    font-size: 21px;
}

.box_text_footer p {
    color: #a5abb0;
    font-size: 15px;
    letter-spacing: 0.16px;
}

.new_footer {
    padding-top: 20px;
}

.section_grey_darker h4, .section_grey_darker .h4 {
    color: #ffffff;
}

.box_text_footer {
    border-top: 1px solid #3e484f;
    padding: 50px 20px 50px 20px;
    color: #a5abb0;
    font-size: 0.88em;
}

.box_text_footer p a {
    color: #a5abb0;
    display: inline-block;
}

.footer-wrapper a {
    text-decoration: none;
}

.logo_wrapper img {
    width: 100%;
    display: block;
    overflow: hidden;
    margin: 0 0 0 0;
    transition: all 0.3s ease;
}

footer a {
  color: white;
  margin: 0 8px 0 8px;
  text-decoration: underline;
}


footer a:hover {
  color: white;
}

ul.footer_links li a {
    color: #65dbde;
    margin: 0 8px 0 8px;
    text-decoration: underline;
}

ul.footer_links {
  list-style: none;
  list-style-image: none;
  list-style-image: none;
}

ul.footer_links li {
    display: inline;
}

/*
 * HBarChart
 *
 */


.hb-grid-hline .tick line,
.hb-grid-hline .domain {
  stroke: #3333330f;
}
.hb-title {
  font-size: 20px;
  text-anchor: middle;
  fill: #333;
}
.hb-bar {
  fill: #06c;
  stroke: #06f;
}
.hb-axis .tick line {
  stroke: #fff;
}
.hb-axis .tick text {
  fill: #19191a;
  font-family: "Titillium Web";
  font-size: 1 rem;
}
.hb-axis .domain {
  stroke: #fff;
}

.hb-line {
  fill: none;
  stroke: #333;
  stroke-width: 3;
}
.hb-bartext {
  color: black;
  fill: black
}

.overlay{
  z-index:33030;
  width: 100vw;
  height: 100vh;
  top:0;
  position: fixed;
  left:0;
  background: black;
  opacity: 0.3;
}
/**
* BarChart
*
*/
.grid-hline .tick line,
.grid-hline .domain {
  stroke: #ffffff0f;
}
.title {
  font-size: 20px;
  text-anchor: middle;
  fill: #fff;
}
.title-bar-dark{
  font-size: 20px;
  text-anchor: center;
  fill: #000000;
}
.title-bar {
  font-size: 20px;
  text-anchor: middle;
  fill: #fff;
  padding:10px;
}
.max-height-plot-1{
  height: 757px !important;
}
.bg-gradient-bar{
  height: 50%;
  width: 100%;
  position: absolute;
  bottom:0;
  left:0px;
  background: #5bc0de;

}
.container-info{
  justify-content: flex-end;
  position: relative;
  bottom: -34px;
  left: 26px;
  z-index:99;
}
.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-top: 4px;
}
.legend {
  color: white;
  margin-left: 10px;
}
.circle-info{

  height: 20px;
  width: 20px;
  background-color:transparent;
  color:black;
  border:2px solid black;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
  display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.circle-info:hover{
  cursor: pointer;
}
.bar {
  fill: #fff;
  stroke: #fff;
}
.axis .tick line {
  stroke: #fff;
}
.axis .tick text {
  fill: #fff;
}
.axis-dark .tick line {
  stroke: #fff;
}
.axis-dark .tick text {
  fill: #000000;
}

.axis-dark .domain {
  stroke: #000000;
}
.axis-dark .domain {
  stroke: #000000;;
}

.line {
  fill: none;
  stroke: #fff;
  stroke-width: 3;
}
.bartext {
  color: #fff;
  fill: #fff
}
.bartext-dark{
  fill:#000000;
  font-size: 1.8rem;
}
.max-width-total{
  max-width: 450px;
}
.position-powerbi{
  position: relative;
  /* bottom:50px;  */
}
/* .map-area{
  zoom:0.77;
} */
@media (max-width: 992px) {
  .map-area{
    zoom:0.77;
  }

  .total_gender {
    font-size: 20px;
  }

  testo-info-campania{
    text-align: center;
  }
}

/**
* FONT SIZE
*
*/
.font-50{
  font-size:50px;
}

.font-30{
  font-size: 30px;
}


.font-25{
  font-size: 25px;
}

.font-22{
  font-size: 22px;
}

.bold {
  font-weight: bold;
}

.numeri_box {
  font-size: 40px;
}

/*
loader*/
.box-loader {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}


/*position*/

.position-text-lg{
  position: absolute;
  bottom:0;
  left:0;
}
.border-pink{
  /* border-left: 3px solid #E31F83; */
    padding-left: 10px;
    font-size: 40px;
    height: 59%;
}
.center-logo{
    width: calc(50% + 80px);
    overflow: auto;
    margin: auto;
    position: absolute;
    top: -15px;
    right: 0;
}
.border-blu {
  border-left: 3px solid #5bc0de;
  padding-left: 10px;
  height: 35%;
}